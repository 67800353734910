import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Route,
} from "react-router-dom";

import logo from "../assets/logo.png";

function MainNavBar() {
  return (
    <Navbar expand="lg" sticky="top" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          <img
            className="d-inline-block align-top"
            src={logo}
            alt="Apavloff"
            height="30"
            width="30"
          />
          Apavloff
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg}`} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link as={NavLink} to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={NavLink} to="/products">
                  Products
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contact">
                  Contact
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar.Collapse>
        {/*</Navbar.Toggle>*/}
      </Container>
    </Navbar>
  );
}

// function MainNavBar() {
//   return (
//     <Navbar expand="lg" sticky="top" bg="dark" variant="dark">
//       <Container fluid>
//         <Navbar.Brand as={NavLink} to="/">
//           <img
//             className="d-inline-block align-top"
//             src={logo}
//             alt="Apavloff"
//             height="30"
//             width="30"
//           />
//           Apavloff
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg}`} />
//         <Navbar.Offcanvas
//           id={`offcanvasNavbar-expand-lg`}
//           aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
//           placement="end"
//         >
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
//               Offcanvas
//             </Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body>
//             <Nav className="justify-content-end flex-grow-1 pe-3">
//               <Nav.Link as={NavLink} to="/">
//                 Home
//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/products">
//                 Products
//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/contact">
//                 Contact
//               </Nav.Link>
//             </Nav>
//           </Offcanvas.Body>
//         </Navbar.Offcanvas>
//       </Container>
//     </Navbar>
//   );
// }

export default MainNavBar;
