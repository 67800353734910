import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow() {
  return (
    <div class="social-container">
        <h3>Social Follow</h3>
        {/*<a href="https://www.youtube.com/c/jamesqquick"
            className="youtube social">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
  </a>*/}
        <a href="https://www.facebook.com/andrew.pavlov.186/"
            className="facebook social">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://www.linkedin.com/in/andreipavlov1982/" className="linkedin social">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://www.instagram.com/nevaeh.pavlov/"
            className="instagram social">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
    </div>
  );
}