import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function TrackToKml() {
  let navigate = useNavigate();
  return (
    <div className="d-grid gap-2">
      <h1>Track to KML</h1>
      <p>
        This application allow to track your positions and after convert to kml
        file and send to email.
      </p>
      <Button
        variant="primary"
        size="lg"
        onClick={() =>
          window.open(
            "https://play.google.com/store/apps/details?id=com.nevaeh.track_to_kml&hl=ru&gl=US",
            "_blank"
          )
        }
      >
        Open
      </Button>
      <Button
        variant="primary"
        size="lg"
        onClick={() => navigate("/products/trackToKml/privacyNotice")}
      >
        PRIVACY NOTICE
      </Button>
    </div>
  );
}

export default TrackToKml;
