import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { useNavigate, useLocation,  Routes,
      NavLink,
      Route  } from "react-router-dom";

import PrivacyNotice from './privacyNotice'

function Other() {
      let navigate = useNavigate(); 
      const routeChangeTrackToKmlPrivaceNotice = () =>{ 
            let path = `privacyNotice`; 
            navigate(path);
      }

      return (
        <div>
        <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title>TrackToKml</Card.Title>
                <Card.Text>
                    This application allow write your track by smartphone and convert it to kml file.
                </Card.Text>
                <Button variant="primary" onClick={() => navigate("trackToKml")}>TrackToKML</Button>
            </Card.Body>
        </Card>
        

    </div>
      )
}

export default Other;