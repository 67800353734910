import backgroundImage from "../assets/backgroundImage.jpg"


function Home() {
    return <div style={styles.container}></div>;
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
    }
  };

export default Home;