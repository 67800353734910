import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import trackToKML from "../assets/trackToKML.png";
import droneradar from "../assets/droneradar.png";
import photoload from "../assets/photoload.png";
import { useNavigate } from "react-router-dom";

function Products() {
  let navigate = useNavigate();

  return (
    <div>
      <Row xs={1} md={2} className="g-4">
        <Col style={{ width: "18rem" }}>
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={trackToKML} />
            <Card.Body>
              <Card.Title>TrackToKml</Card.Title>
              <Row style={{ height: "5rem" }}>
                <Card.Text>
                  This application allow write your track by smartphone and
                  convert it to kml file.
                </Card.Text>
              </Row>
              <Row>
                <Button
                  variant="primary"
                  onClick={() => navigate("/products/trackToKml")}
                >
                  TrackToKML
                </Button>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "18rem" }}>
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={droneradar} />
            <Card.Body>
              <Card.Title>DroneRadar</Card.Title>
              <Row style={{ height: "5rem" }}>
                <Card.Text>
                  This service allow obsever registered UAV on the map.
                </Card.Text>
              </Row>
              <Row>
                <Button
                  variant="primary"
                  onClick={() => navigate("/products/droneradar")}
                >
                  DroneRadar
                </Button>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "18rem" }}>
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={photoload} />
            <Card.Body>
              <Card.Title>Photoload</Card.Title>
              <Row style={{ height: "5rem" }}>
                <Card.Text>
                  This service allows you to manage the storage of photos taken
                  by drone.
                </Card.Text>
              </Row>
              <Row>
                <Button
                  variant="primary"
                  onClick={() => navigate("/products/photoload")}
                >
                  Photoload
                </Button>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Products;
