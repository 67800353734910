import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Photoload() {
  let navigate = useNavigate();
  return (
    <div className="d-grid gap-2">
      <h1>Photoload</h1>
      <p>
        This service allows you to manage the storage of photos taken by drone.
      </p>
      <Button
        variant="primary"
        size="lg"
        onClick={() => window.open("https://photoload.apavloff.ru", "_blank")}
      >
        Open
      </Button>
    </div>
  );
}

export default Photoload;
