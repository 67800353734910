import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function DroneRadar() {
  let navigate = useNavigate();
  return (
    <div className="d-grid gap-2">
      <h1>DroneRadar</h1>
      <p>This service allow obsever registered UAV on the map.</p>
      <Button
        variant="primary"
        size="lg"
        onClick={() => window.open("https://droneradar.apavloff.ru", "_blank")}
      >
        Open
      </Button>
    </div>
  );
}

export default DroneRadar;
