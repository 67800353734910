import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainNavBar from "./components/navbar";
import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Route,
} from "react-router-dom";

import Home from "./pages/mainPage";
import Products from "./pages/productsPage";
import Contact from "./pages/contactPage";
import TrackToKml from "./pages/products/trackToKml/trackToKml";
import DroneRadar from "./pages/products/droneRadar";
import Photoload from "./pages/products/photoload";
import Other from "./pages/products/trackToKml/Other";

import PrivacyNotice from "./pages/products/trackToKml/privacyNotice";

function App() {
  return (
    <div className="App">
      <Router>
        <MainNavBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/products/*" exact element={<Products />} />
          <Route path="/products/trackToKml" exact element={<TrackToKml />} />
          <Route
            path="/products/trackToKml/privacyNotice"
            exact
            element={<PrivacyNotice />}
          />
          <Route path="/products/droneRadar" exact element={<DroneRadar />} />
          <Route path="/products/photoload" exact element={<Photoload />} />
          <Route path="/products/other" exact element={<Other />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
