import logo from "../assets/logo.png"
import SocialFollow from "../components/SocialFollow"

function Contact() {
    return (
      <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img className="d-inline-block align-left" src={logo} alt='Apavloff' height="128" width="128" />
                email: nevaeh.pavlov@gmail.com

            </div>
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
                      <SocialFollow />
            </div>
      </div>
            
    )
}

export default Contact;